import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "list" ]

  add(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML;

    var newElem = document.createElement('div');
    newElem.innerHTML = content;
    this.listTarget.appendChild(newElem);

    jQuery(this.listTarget.firstElementChild.querySelector('[data-provide="slider"]')).slider();
  }

  remove(event) {
    event.preventDefault()

    if(this.listTarget.childElementCount > 1) {
      this.listTarget.lastElementChild.remove();
    }
  }
}
