import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'active' ]

  toggle(event) {
    let formData = new FormData()
    formData.append(this.data.get('param-name'), this.activeTarget.checked)

    fetch(this.data.get('update-url'), {
        body: formData,
        method: 'PATCH',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': getMetaValue('csrf-token')
        },
      }
    ).then(function(response) {
        if (response.status != 204) {
          event.target.checked = !event.target.checked
        }
      }
    )
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}
