import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['value', 'rangeFrom', 'rangeTo', 'slider'];

  connect() {
    this.updateValue();
  }

  updateValue() {
    const vals = [this.rangeFromTarget.value, this.rangeToTarget.value].sort();
    this.valueTarget.innerHTML = this.timeToStr(vals[0]) + " - " + this.timeToStr(vals[1]);
  }

  remove(event) {
    event.preventDefault();
    this.sliderTarget.remove();
  }

  timeToStr(duration) {
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor((duration / (60 * 60))) % 12 || 12;
    const ampm = (duration < 60 * 60 * 12 || duration == 60 * 60 * 24) ? 'am' : 'pm';
    const minutesStr = (minutes < 10) ? (':0' + minutes) : (':' + minutes);
    return hours + minutesStr + ' ' + ampm;
  }
}
